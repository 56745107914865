import { DelayTime } from '@/constants';
import { usePreventBodyScroll } from '@/hooks';
import { useDelayedShipmentPopUp } from '@/modules/pmt/components/DelayedShipmentPopup/hooks';
import { useTrackingOnboardingPopUp } from '@/modules/pmt/components/TrackingOnboardingPopup/hooks';
import { useShipmentDetailsContext } from '@/modules/pmt/context';
import { type ReactNode, createContext, useContext, useMemo } from 'react';
import { useRequestLoginDialog } from '../components/RequestLoginDialog/hooks';

interface IPopUpsContext {
  onboardingPopUpDelayTime: DelayTime;
  onCloseOnboardingPopUp: () => void;
  showDelayedShipmentPopUp: boolean;
  onCloseDelayedShipmentPopUp: () => void;
  showRequestLoginDialog: boolean;
  setShowRequestLoginDialog: (show: boolean) => void;
  isFromRequestLoginDialog: boolean;
  setIsFromRequestLoginDialog: (isFromRequestLoginDialog: boolean) => void;
  pauseRunningPopUps: (isPaused: boolean) => void;
}

const PopUpsContext = createContext<IPopUpsContext>({
  onboardingPopUpDelayTime: DelayTime['10s'],
  onCloseOnboardingPopUp: () => null,
  showDelayedShipmentPopUp: false,
  onCloseDelayedShipmentPopUp: () => null,
  showRequestLoginDialog: false,
  setShowRequestLoginDialog: () => null,
  isFromRequestLoginDialog: false,
  setIsFromRequestLoginDialog: () => null,
  pauseRunningPopUps: () => null,
});

export const PopUpsProvider = ({ children }: { children: ReactNode }) => {
  const { showDetailsView, currentShipment } = useShipmentDetailsContext();
  const { showRequestLoginDialog, setShowRequestLoginDialog, isFromRequestLoginDialog, setIsFromRequestLoginDialog } =
    useRequestLoginDialog();
  const { delayTime, closePopUp, pauseOpenOnboardingPopup } = useTrackingOnboardingPopUp({
    showRequestLoginDialog,
    setIsFromRequestLoginDialog,
  });
  const { showDelayedShipmentPopUp, onCloseDelayedShipmentPopUp, pauseDelayedShipmentPopUp } = useDelayedShipmentPopUp({
    showDetailsView,
    pauseOpenOnboardingPopup,
    currentShipment: currentShipment?.data,
  });

  const pauseRunningPopUps = (isPaused: boolean) => {
    pauseOpenOnboardingPopup(isPaused);
    pauseDelayedShipmentPopUp(isPaused);
  };

  usePreventBodyScroll(showDelayedShipmentPopUp || showRequestLoginDialog);

  const ctxValue = useMemo(
    () => ({
      onboardingPopUpDelayTime: delayTime,
      onCloseOnboardingPopUp: closePopUp,
      showDelayedShipmentPopUp,
      onCloseDelayedShipmentPopUp,
      showRequestLoginDialog,
      setShowRequestLoginDialog,
      isFromRequestLoginDialog,
      setIsFromRequestLoginDialog,
      pauseRunningPopUps,
    }),
    [
      closePopUp,
      delayTime,
      onCloseDelayedShipmentPopUp,
      showDelayedShipmentPopUp,
      isFromRequestLoginDialog,
      setIsFromRequestLoginDialog,
      pauseRunningPopUps,
    ],
  );

  return <PopUpsContext.Provider value={ctxValue}>{children}</PopUpsContext.Provider>;
};

export const usePopUpsContext = () => {
  const ctx = useContext(PopUpsContext);

  if (!ctx) {
    throw new Error('usePopUpsContext must be used within a PopUpsProvider');
  }

  return ctx;
};
