import { DEFAULT_LANGUAGE } from '@/constants';
import type { IPmtOnboardingPopup } from '@/interfaces/tracking';
import { languageFieldsMapping, translationDataModify } from '@/utils';
import callContentful from './base.fetchers';

import { LanguageCode } from '@/interfaces';

type GetPmtOnboardingPopUps = (args: {
  isPreviewMode: boolean;
  languageCode?: LanguageCode;
}) => Promise<{
  pmtOnboardingPopup: IPmtOnboardingPopup[];
  pmtEmailSyncOnboardingPopup: IPmtOnboardingPopup[];
}>;

const getPmtOnboardingPopUp: GetPmtOnboardingPopUps = async ({
  isPreviewMode,
  languageCode = LanguageCode.English,
}) => {
  const mappedLanguageFields = languageFieldsMapping(languageCode);
  const query = `
    {
      defaultPmtOnboardingPopup: pmtOnboardingPopupCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
        where: {
          isEmailSyncPopup_not: true
        }
      ) {
        items {
          sys {
            id
          }
          identifier
          joinUsHeader: joinUsHeader1
          joinUsValuePropositionList: joinUsValuePropositionList1Collection {
            items {
              sys {
                id
              }
              title
              contentType
              url
              description
            }
          }
          joinUsDesktopMainText: joinUsDesktopMainText1
          joinUsSubtext: joinUsSubtext1
          subPmcNewsletterText: subPmcNewsletterText1
          newsletterSubText: newsletterSubText1
          heroImage {
            contentType
            url
            description
          }
          zapierHookId
          newUserTriggerTimes
          returnUserTriggerTimes
          trigger
          variant
          loginByEmail
        }
      }
      pmtOnboardingPopup: pmtOnboardingPopupCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 10
        where: {
          isEmailSyncPopup_not: true
        }
      ) {
        items {
          sys {
            id
          }
          identifier
          ${mappedLanguageFields.getField('joinUsHeader')}
          ${mappedLanguageFields.getField('joinUsDesktopMainText')}
          ${mappedLanguageFields.getField('joinUsSubtext')}
          ${mappedLanguageFields.getField('subPmcNewsletterText')}
          ${mappedLanguageFields.getField('newsletterSubText')}
          ${mappedLanguageFields.getField('joinUsValuePropositionList')}Collection {
            items {
              sys {
                id
              }
              title
              contentType
              url
              description
            }
          }
          heroImage {
            contentType
            url
            description
          }
          zapierHookId
          newUserTriggerTimes
          returnUserTriggerTimes
          trigger
          variant
          loginByEmail
        }
      }
      defaultPmtEmailSyncOnboardingPopup: pmtOnboardingPopupCollection(
        preview: ${isPreviewMode}
        locale: "${DEFAULT_LANGUAGE}"
        limit: 1
        where: {
          isEmailSyncPopup: true
        }
      ) {
        items {
          sys {
            id
          }
          identifier
          joinUsHeader: joinUsHeader1
          joinUsValuePropositionList: joinUsValuePropositionList1Collection {
            items {
              sys {
                id
              }
              title
              contentType
              url
              description
            }
          }
          joinUsDesktopMainText: joinUsDesktopMainText1
          joinUsSubtext: joinUsSubtext1
          subPmcNewsletterText: subPmcNewsletterText1
          newsletterSubText: newsletterSubText1
          heroImage {
            contentType
            url
            description
          }
          desktopGoogleCTA: desktopGoogleCta1
          zapierHookId
          newUserTriggerTimes
          returnUserTriggerTimes
          trigger
          variant
          loginByEmail
        }
      }
      pmtEmailSyncOnboardingPopup: pmtOnboardingPopupCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 10
        where: {
          isEmailSyncPopup: true
        }
      ) {
        items {
          sys {
            id
          }
          identifier
          ${mappedLanguageFields.getField('joinUsHeader')}
          ${mappedLanguageFields.getField('joinUsDesktopMainText')}
          ${mappedLanguageFields.getField('joinUsSubtext')}
          ${mappedLanguageFields.getField('desktopGoogleCta')}
          ${mappedLanguageFields.getField('subPmcNewsletterText')}
          ${mappedLanguageFields.getField('newsletterSubText')}
          ${mappedLanguageFields.getField('joinUsValuePropositionList')}Collection {
            items {
              sys {
                id
              }
              title
              contentType
              url
              description
            }
          }
          heroImage {
            contentType
            url
            description
          }
          zapierHookId
          newUserTriggerTimes
          returnUserTriggerTimes
          trigger
          variant
          loginByEmail
        }
      }
    }
  `;

  const slug = `/${languageCode === LanguageCode.English ? '' : '/'}track-my-parcel`;
  const response = await callContentful(query, isPreviewMode, slug, 'pmtPopup.fetchers.getPmtOnboardingPopUp');
  const defaultPmtPopupData = response?.data?.defaultPmtOnboardingPopup?.items?.filter(Boolean)?.[0] || null;
  const pmtPopupData: IPmtOnboardingPopup[] = response.data?.pmtOnboardingPopup?.items;

  const defaultPmtEmailSyncPopupData =
    response?.data?.defaultPmtEmailSyncOnboardingPopup?.items?.filter(Boolean)?.[0] || null;

  const pmtEmailSyncPopupData: IPmtOnboardingPopup[] = response.data?.pmtEmailSyncOnboardingPopup?.items;

  return {
    pmtOnboardingPopUp: pmtPopupData?.map((pmtOnboardingPopup) =>
      translationDataModify(defaultPmtPopupData, pmtOnboardingPopup),
    ),
    pmtEmailSyncOnboardingPopUp: pmtEmailSyncPopupData?.map((emailSyncOnboardingPopup) =>
      translationDataModify(defaultPmtEmailSyncPopupData, emailSyncOnboardingPopup),
    ),
  } as any;
};

export { getPmtOnboardingPopUp };
