interface IServerSharedData {
  isLoggedIn: boolean;
  authenticatorAccessToken: string | undefined;
  [key: string]: unknown;
}

// Used for server-side API calls and initial client hydration
const serverSharedData = {} as IServerSharedData;

export default serverSharedData;
